// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '~@angular/material' as mat;
// Plus imports for other components in your app.
@use './app/modules/home/home.component' as home;
@use './app/core/components/file-dropper/file-dropper.component' as filedropper;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

@import "primeflex/primeflex.css";

.full-page {
  min-height: calc(100vh - 104px);
}

.full-page-with-footer {
  min-height: calc(100vh - 104px - 64px);
}


$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);
$dark-dividers: rgba(black, 0.12);
$dark-focused: rgba(black, 0.12);
$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

$app-green-palette: (
  50: #e7eee6,
  100: #cdd7c9,
  200: #a8bf9f,
  300: #879b7f,
  400: #64785d,
  500: #5a6d53,
  600: #395034,
  700: #30422a,
  800: #2c3b25,
  900: #212d1d,
  A100: #4c5e4c,
  A200: #3c4235,
  A400: #273127,
  A700: #394435,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$app-orange-palette: (
  50: #ffdc5c,
  100: #ffdc5c,
  200: #ffdc5c,
  300: #ffdc5c,
  400: #ffdc5c,
  500: #ccab28,
  600: #977c00,
  700: #977c00,
  800: #977c00,
  900: #977c00,
  A100: #ffdc5c,
  A200: #ccab28,
  A400: #ccab28,
  A700: #977c00,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$sajccc-primary: mat.define-palette($app-green-palette);
$sajccc-accent: mat.define-palette($app-orange-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$sajccc-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$sajccc-theme: mat.define-light-theme((
  color: (
    primary: $sajccc-primary,
    accent: $sajccc-accent,
    warn: $sajccc-warn,
  )
));



// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($sajccc-theme);
@include home.theme($sajccc-theme);
@include filedropper.theme($sajccc-theme);


/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.page-container {
  min-height: 100%;
  min-width: 100%;
}

.full-width {
  width: 100%;
}

.mat-checkbox-layout{
  white-space: normal !important;

}
.mat-checkbox-inner-container{
  margin-top: 4px;
}


$spaceAmounts: (0:0, 1:4, 2:8, 3:12, 4:16, 5:20, 6:24, 7:32, 8:64); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $i, $space in $spaceAmounts {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$i} {
      margin-#{$side}: #{$space}px !important;
    }

    .m-#{$i} {
      margin: #{$space}px !important;
    }

    .mx-#{$i} {
      margin-left: #{$space}px !important;
      margin-right: #{$space}px !important;
    }

    .my-#{$i} {
      margin-top: #{$space}px !important;
      margin-bottom: #{$space}px !important;
    }

    .p#{str-slice($side, 0, 1)}-#{$i} {
      padding-#{$side}: #{$space}px !important;
    }

    .p-#{$i} {
      padding: #{$space}px !important;
    }

    .px-#{$i} {
      padding-left: #{$space}px !important;
      padding-right: #{$space}px !important;
    }

    .py-#{$i} {
      padding-top: #{$space}px !important;
      padding-bottom: #{$space}px !important;
    }
  }
}
