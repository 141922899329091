// _carousel-theme.scss

@use 'sass:map';
@use '~@angular/material' as mat;

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);

  // Get the primary color palette from the color-config.
  $primary-palette: map.get($color-config, 'primary');
  $danger-palette: map.get($color-config, 'warn');

  .upload-file-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: rgba(0,0,0,0);
    height: 200px;
    width: 100%;
    margin: 20px auto;
    border: 2px dashed mat.get-color-from-palette($primary-palette, 500);
    border-radius: 4px;

    &.danger {
      border: 2px dashed mat.get-color-from-palette($danger-palette, 500);
    }

    & .upload-icon {
      width: 100px;
      height: 100px;
      background-image: url("/assets/images/icons/cloud.png");
      background-repeat: no-repeat;
      background-size: 100px;
      background-position: center;
    }

    & .upload-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: mat.get-color-from-palette($primary-palette, '50-contrast');
    }
  }

  .upload-file-container:hover {
    cursor: pointer;
    background-color: mat.get-color-from-palette($primary-palette, 100) !important;
    opacity: 0.8;
  }

  .files-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 10px auto;
    background: #FEFEFE;
    border: 1px dashed;
    border-radius: 4px;
    padding: 5px;
    color: mat.get-color-from-palette($primary-palette, 500);

    p {
      margin-bottom: 0 !important;
    }
  }

  .files-list .delete-file {
    background: transparent;
    border: none;
    cursor: pointer;
  }

  .files-list .delete-file img {
    width: 30px;
  }
}

@mixin typography($theme) {
  // Get the typography config from the theme.
  $typography-config: mat.get-typography-config($theme);
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
