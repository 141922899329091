// _carousel-theme.scss

@use 'sass:map';
@use '~@angular/material' as mat;

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);

  // Get the primary color palette from the color-config.
  $primary-palette: map.get($color-config, 'primary');

  @media screen and (min-width: 959px) {
    .hero {
      height: 50vw;
    }
  }

  @media screen and (max-width: 959px) {
    .hero {
      height: auto;
    }

    .box-multi {
      padding-bottom: 50%;
      position: relative;

      .box-grid {
        position: absolute;
      }
    }
  }

  @media screen and (max-width: 599px) {
    .box-multi {
      padding-bottom: 100%;
    }
  }

  .hero {
    position: relative;

    h1, h2, h3, h4 {
      margin: 0;
    }

    h1 {
      font-size: 4em;
      line-height: 1em;
      font-weight: 700;
      text-shadow: 0 1px 0 #ccc,
      0 1.4px 0 #c9c9c9,
      0 1.8px 0 #bbb,
      0 2.2px 0 #b9b9b9,
      0 2.6px 0 #aaa,
      0 3px 2px rgba(0, 0, 0, .1),
      0 0 5px rgba(0, 0, 0, .1),
      0 1px 3px rgba(0, 0, 0, .3),
      0 2px 5px rgba(0, 0, 0, .2),
      0 3px 10px rgba(0, 0, 0, .25),
      0 5px 10px rgba(0, 0, 0, .2),
      0 10px 20px rgba(0, 0, 0, .15);
    }

    h2 {
      font-size: 2em;
      font-weight: 600;
      text-shadow: 0 0 5px rgba(0, 0, 0, .1),
      0 3px 10px rgba(0, 0, 0, .05),
      0 10px 20px rgba(0, 0, 0, .02);
    }

    h3 {
      font-size: 1.5em;
      font-weight: 500;
      text-shadow: 0 0 5px rgba(0, 0, 0, .1),
      0 3px 10px rgba(0, 0, 0, .05),
      0 10px 20px rgba(0, 0, 0, .02);
    }

    .box-small {
      padding: 16px;
    }

    .box-1 {
      padding: 24px 24px 24px 48px;
      background-color: mat.get-color-from-palette($primary-palette, 500);
      color: mat.get-color-from-palette($primary-palette, '500-contrast');

      .text {

      }
    }

    .box-3 {
      background-color: mat.get-color-from-palette($primary-palette, 500);
      color: mat.get-color-from-palette($primary-palette, '500-contrast');
    }

    .box-4 {
      background-color: mat.get-color-from-palette($primary-palette, 500);
      color: mat.get-color-from-palette($primary-palette, '500-contrast');
    }

    .box-5 .logo {
      width: auto;
      height: 100%;
      background: url("/assets/images/logo.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }

  .container {
    h1 {
      font-size: 2.5em;
    }
    width: auto;

    margin: 48px;
    @media screen and (max-width: 599px) {
      margin: 8px;

    }
  }
}

@mixin typography($theme) {
  // Get the typography config from the theme.
  $typography-config: mat.get-typography-config($theme);

  .hero {
    font-family: mat.font-family($typography-config);
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
